import { capitalize } from '@/core'

export const timeslotsTableFieldsDesktopStep = [
  {
    prop: 'num_auto',
    label: 'Номер авто',
    width: '180',
    key: 0,
    disabled: true,
    fixed: true,
  },
  {
    prop: 'timeslot',
    label: 'Тайм слот',
    width: '260',
    key: 2,
    headerIcon: true,
    sortKey: 'time',
  },
  {
    prop: 'culture',
    label: 'Культура',
    key: 3,
    width: '250',
  },
  {
    prop: 'truck_status',
    label: 'Статус авто',
    width: '250',
    key: 4,
    headerIcon: true,
    sortKey: 'status_order',
  },
  {
    prop: 'submit_status',
    label: 'Статус тайм слота',
    key: 10,
    width: '250',
  },
  {
    prop: 'truck_type_code',
    label: 'Тип авто',
    width: '150',
    key: 19,
  },
  {
    prop: 'parking_time',
    label: 'Заезд на парковку',
    width: '180',
    key: 20,
  },
  {
    prop: 'buffer_time',
    label: 'Заезд в буфер',
    width: '180',
    key: 21,
  },
  {
    prop: 'terminal_time',
    label: 'Окончил рейс',
    width: '180',
    key: 22,
  },
  {
    prop: 'culture_id',
    label: 'Id культуры',
    width: '150',
    key: 5,
  },
  {
    prop: 'date_cre',
    label: 'Время создания',
    key: 6,
    width: '180',
  },
  {
    prop: 'id',
    label: 'Id тайм слота',
    key: 8,
    width: '180',
    headerIcon: true,
    sortKey: 'id',
  },
  {
    prop: 'phone',
    label: 'Телефон перевозчика',
    key: 9,
    width: '180',
  },
  {
    prop: 'unload_id',
    label: 'Id терминала',
    key: 14,
    width: '180',
  },
  {
    prop: 'unload_name',
    label: 'Терминал',
    key: 15,
    width: '180',
  },
  {
    prop: 'user_created_phone',
    label: 'Создатель тайм слота',
    key: 16,
    width: '180',
  },
  {
    prop: 'user_deleted_name',
    label: 'Удалил таймслот',
    key: 28011200,
    width: '180',
  },
  {
    prop: 'user_deleted_id',
    label: 'Id удалившего таймслот',
    key: 28011202,
    width: '180',
  },
  {
    prop: 'plate_trailer',
    label: 'Номер прицепа',
    key: 28011203,
    width: '180',
  },
  {
    prop: 'truck_spindles_count',
    label: 'Количество осей',
    key: 28011204,
    width: '180',
  },
  {
    prop: 'driver_name',
    label: 'ФИО водителя',
    key: 28011206,
    width: '180',
  },
  {
    prop: 'driver_phone',
    label: 'Телефон водителя',
    key: 7,
    width: '180',
  },
  {
    prop: 'exporter_name',
    label: 'Экспортер',
    key: 379,
    width: '300',
  },
  {
    prop: 'supplier_name',
    label: 'Поставщик из квоты',
    key: 3791,
    width: '300',
  },
  {
    prop: 'extras_common_supplier_inn',
    label: 'Инн поставщика',
    key: 28011207,
    width: '180',
  },
  {
    prop: 'extras_common_supplier_name',
    label: 'Поставщик по ИНН',
    key: 28011208,
    width: '180',
  },
]
export const timeslotsTableFieldsDesktop = [
  {
    prop: 'num_auto',
    label: 'Номер авто',
    width: '180',
    key: 0,
    disabled: true,
    fixed: true,
  },
  {
    prop: 'timeslot',
    label: 'Тайм слот',
    width: '260',
    key: 2,
    headerIcon: true,
    sortKey: 'time',
  },
  {
    prop: 'culture',
    label: 'Культура',
    key: 3,
    width: '250',
  },
  {
    prop: 'truck_status',
    label: 'Статус авто',
    width: '250',
    key: 4,
    headerIcon: true,
    sortKey: 'status_order',
  },
  {
    prop: 'submit_status',
    label: 'Статус тайм слота',
    key: 10,
    width: '230',
  },
  {
    prop: 'truck_type_code',
    label: 'Тип авто',
    width: '150',
    key: 19,
  },
  {
    prop: 'parking_time',
    label: 'Заезд на парковку',
    width: '180',
    key: 20,
  },
  {
    prop: 'buffer_time',
    label: 'Заезд в буфер',
    width: '180',
    key: 21,
  },
  {
    prop: 'terminal_time',
    label: 'Окончил рейс',
    width: '180',
    key: 22,
  },
  {
    prop: 'culture_id',
    label: 'Id культуры',
    width: '150',
    key: 5,
  },
  {
    prop: 'date_cre',
    label: 'Время создания',
    key: 6,
    width: '180',
  },
  {
    prop: 'driver_phone',
    label: 'Телефон водителя',
    key: 7,
    width: '180',
  },
  {
    prop: 'driver_name',
    label: 'ФИО водителя',
    key: 28011206,
    width: '180',
  },
  {
    prop: 'id',
    label: 'Id тайм слота',
    key: 8,
    width: '180',
    headerIcon: true,
    sortKey: 'id',
  },
  {
    prop: 'phone',
    label: 'Телефон перевозчика',
    key: 9,
    width: '180',
  },
  {
    prop: 'unload_id',
    label: 'Id терминала',
    key: 14,
    width: '180',
  },
  {
    prop: 'unload_name',
    label: 'Терминал',
    key: 15,
    width: '180',
  },
  {
    prop: 'user_created_phone',
    label: 'Создатель тайм слота',
    key: 16,
    width: '180',
  },
  {
    prop: 'exporter_name',
    label: 'Экспортер',
    key: 379,
    width: '300',
  },
  {
    prop: 'supplier_name',
    label: 'Поставщик из квоты',
    key: 3791,
    width: '300',
  },
  {
    prop: 'extras_common_supplier_inn',
    label: 'ИНН',
    key: 373,
    width: '150',
  },
  {
    prop: 'extras_common_supplier_name',
    label: 'Поставщик по ИНН',
    key: 374,
    width: '300',
  },
  {
    prop: 'user_deleted_name',
    label: 'Удалил таймслот',
    key: 28011200,
    width: '180',
  },
  {
    prop: 'user_deleted_id',
    label: 'Id удалившего таймслот',
    key: 28011202,
    width: '180',
  },
]

export const managerChangeTable = 'managerChangeTable'

export const managerTableDropdownMenuList = [
  {
    id: 1,
    label: 'Изменить таблицу',
    value: managerChangeTable,
    class: 'm-b-12',
  },
]

export const timeslotStatuses = {
  default: { title: 'не определено', icon: 'el-icon-info' },
  arrived: { title: 'закончил рейс', icon: 'el-icon-info' },
  not_stated: { title: 'в пути', icon: 'el-icon-success' },
  parked_far: { title: 'на парковке', icon: 'el-icon-success' },
  manual: { title: 'ручной', icon: 'el-icon-success' },
  parked_close: { title: 'в буфере у кпп', icon: 'el-icon-warning' },
  late: { title: 'опоздал', icon: 'el-icon-error' },
  convertedToManual: {
    title: 'конвертирован в ручной',
    icon: 'el-icon-warning',
  },
  submitted: { title: 'взят в приложении', icon: 'el-icon-warning' },
  detached: { title: 'отказ', icon: 'el-icon-error' },
  denied: { title: 'отклонен', icon: 'el-icon-close' },
  dropped: { title: 'удален пользователем', icon: 'el-icon-error' },
  moved: { title: 'перенесен', icon: 'el-icon-close' },
  expired: { title: 'истек', icon: 'el-icon-timer' },
  closed: { title: 'квота закрыта', icon: 'el-icon-error' },
  dropOnNew: { title: 'удален из-за нового ТС', icon: 'el-icon-error' },
  deletedByAdmin: { title: 'удален админом', icon: 'el-icon-error' },
}

export const statusesList = [
  {
    id: 'not_stated',
    name: timeslotStatuses['not_stated'].title,
    title: capitalize(timeslotStatuses['not_stated'].title),
  },
  {
    id: 'parked_far',
    name: timeslotStatuses['parked_far'].title,
    title: capitalize(timeslotStatuses['parked_far'].title),
  },
  {
    id: 'parked_close',
    name: timeslotStatuses['parked_close'].title,
    title: capitalize(timeslotStatuses['parked_close'].title),
  },
  {
    id: 'arrived',
    name: timeslotStatuses['arrived'].title,
    title: capitalize(timeslotStatuses['arrived'].title),
  },
  {
    id: 'late',
    name: timeslotStatuses['late'].title,
    title: capitalize(timeslotStatuses['late'].title),
  },
  {
    id: 'detached',
    name: timeslotStatuses['detached'].title,
    title: capitalize(timeslotStatuses['detached'].title),
  },
]

export const entriesStatuses = [
  {
    value: 'not_stated',
    label: capitalize(timeslotStatuses['not_stated'].title),
  },
  {
    value: 'parked_far',
    label: capitalize(timeslotStatuses['parked_far'].title),
  },
  {
    value: 'parked_close',
    label: capitalize(timeslotStatuses['parked_close'].title),
  },
  {
    value: 'arrived',
    label: capitalize(timeslotStatuses['arrived'].title),
  },
  {
    value: 'late',
    label: capitalize(timeslotStatuses['late'].title),
  },
  {
    value: 'detached',
    label: capitalize(timeslotStatuses['detached'].title),
  },
]
